import * as React from "react";
// @ts-ignore
import rect from "../../content/pics/Rectangle 2.png"
import "../../css/elements/for-retail.css"
// @ts-ignore
import breadFirst from "../../content/pics/for-retail/bread-1.jpg"
// @ts-ignore
import breadSecond from "../../content/pics/for-retail/bread-2.jpg"
// @ts-ignore
import breadThird from "../../content/pics/for-retail/bread-3.jpg"
// @ts-ignore
import breadFourth from "../../content/pics/for-retail/bread-4.jpg"
// @ts-ignore
import breadFifth from "../../content/pics/for-retail/bread-5.jpg"
// @ts-ignore
import breadSixth from "../../content/pics/for-retail/bread-6.jpg"
// @ts-ignore
import breadSeventh from "../../content/pics/for-retail/bread-7.jpg"
// @ts-ignore
import breadEight from "../../content/pics/for-retail/bread-8.jpg"
// @ts-ignore
import breadNinth from "../../content/pics/for-retail/bread-9.jpg"
// @ts-ignore
import ForRetailCard from "../elements/ForRetailCard.tsx";

export default function ForRetail({setDialog}): React.JSX.Element {

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animate__animated');
                entry.target.classList.add('animate__fadeInUp');
            }
        });
    });

    return <section
        style={{
            marginTop: 75,
            zIndex: 30
        }}
        onLoad={() => {
            const targetElement = document.querySelector(".wwrap");
            if (targetElement) {
                observer.observe(targetElement);
            }

            const header = document.querySelector(".line-center");
            if (header) {
                observer.observe(header);
            }

            const paragraph = document.querySelector(".line-center-p");
            if (paragraph) {
                observer.observe(paragraph);
            }
        }}
    >
        <article className="col-center">
            <span
                className="line-center"
                style={{
                    marginBottom: 25
                }}>
                <h1>Хлеб для ритейла
                </h1>
                <img
                    alt="rect"
                    src={rect}
                    className="rect-bottom"
                />
            </span>
        </article>
        <article className="line-center wwrap">
            <ForRetailCard
                Img={breadFirst}
                Name='Лепешка "Португальская"'
                ShadowExt={true}
                setDialog={setDialog}
            />
            <ForRetailCard
                Img={breadSecond}
                Name="Панини (для сэндвичей)"
                ShadowExt={true}
                setDialog={setDialog}
            />
            <ForRetailCard
                Img={breadThird}
                Name="Лепешка Гречневый ляпун"
                ShadowExt={true}
                setDialog={setDialog}
            />
            <ForRetailCard
                Img={breadFourth}
                Name="Лепешка Ржаной ляпун"
                ShadowExt={true}
                setDialog={setDialog}
            />
            <ForRetailCard
                Img={breadFifth}
                Name="Багет витой"
                ShadowExt={true}
                setDialog={setDialog}
            />
            <ForRetailCard
                Img={breadSixth}
                Name="Панини пшеничный (заморозка)"
                ShadowExt={true}
                setDialog={setDialog}
            />
            <ForRetailCard
                Img={breadSeventh}
                Name="Панини резаная (заморозка)"
                ShadowExt={true}
                setDialog={setDialog}
            />
            <ForRetailCard
                Img={breadEight}
                Name="Чиабатта классическая"
                ShadowExt={true}
                setDialog={setDialog}
            />
            <ForRetailCard
                Img={breadNinth}
                Name="Чиабатта с маслинами"
                ShadowExt={true}
                setDialog={setDialog}
            />
        </article>
    </section>
}